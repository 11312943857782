import { createI18n } from 'vue-i18n';

import en from '../../../translations/en_US.json';
import pl from '../../../translations/pl_PL.json';

const messages = {
  en,
  pl,
};

// Pobranie aktualnego języka strony z WPML
const currentLocale = document.documentElement.lang || 'pl_PL'; // Domyślnie 'pl' jeśli zmienna nie jest ustawiona

export const i18n = createI18n({
  locale: currentLocale, // Ustawienie aktualnego języka
  fallbackLocale: 'pl_PL',  // Fallback na język angielski
  messages,
  legacy: false
});
