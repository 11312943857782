const header = () => {
  const desktopSubMenu = () => {
    const expandingItems = document.querySelectorAll('.menu-item__link--expand');

    const allItemsLink = document.querySelector('.mega-menu__all-items');
    const targetElement = document.querySelector('.el-tabs__nav-scroll');

    if (allItemsLink && targetElement) {
      targetElement.appendChild(allItemsLink);
    }

    expandingItems.forEach(item => {
      const parent = item.parentElement;
      const childNav = parent.querySelector('.menu-item__children--desktop');

      item.addEventListener('click', e => {
        e.preventDefault();
        document.querySelectorAll('.menu-item__children--desktop').forEach(nav => {
          if (nav !== childNav) {
            nav.classList.add('hidden');
          }
        });
        if (childNav) {
          childNav.classList.toggle('hidden');
        }

        const search_bar = document.querySelector('.search__form-container')

        if(!search_bar.classList.contains('hidden')) {
          search_bar.classList.add('hidden')
        }
      });
    });
  }
  desktopSubMenu();

  const mobileSubMenu = () => {
    const header = document.querySelector('.header');
    const mobileHamburger = document.querySelector('.header__mobile-hamburger');

    const handleResize = () => {
      if (window.innerWidth < 1024) {
        header.classList.add('header--mobile');
        addMobileMenuEventListeners();
      } else {
        header.classList.remove('header--mobile');
        removeMobileMenuEventListeners();
      }
    };

    const addMobileMenuEventListeners = () => {
      const expandingItems = document.querySelectorAll('.menu-item__link--expand');
      expandingItems.forEach(item => {
        item.addEventListener('click', handleItemClick);
      });
    };

    const removeMobileMenuEventListeners = () => {
      const expandingItems = document.querySelectorAll('.menu-item__link--expand');
      expandingItems.forEach(item => {
        item.removeEventListener('click', handleItemClick);
      });
    };

    const handleItemClick = (e) => {
      mobileHamburger.classList.add('opacity-0');
      setTimeout(() => {
        mobileHamburger.classList.remove('opacity-0');
      }, 1000);
      const item = e.currentTarget;
      const parent = item.parentElement;
      parent.querySelector('.menu-item__children').classList.add('menu-item__children--active');
      if (parent.querySelector('.menu-item__children').classList.contains('menu-item__children--inactive')) {
        parent.querySelector('.menu-item__children').classList.remove('menu-item__children--inactive');
      }
      const returnMenuButtons = document.querySelectorAll('.submenu__return');
      setTimeout(() => {
        document.querySelector('.header__home-link').classList.add('hidden');
      }, 1000);
      document.querySelector('.header__return').classList.add('header__return--active');
      const headingToReplace = parent.querySelector('.submenu__heading--to-replace').innerHTML;
      document.querySelector('.submenu__heading').innerHTML = headingToReplace;
      document.querySelector('.menu-item__link--search').classList.add('hidden');

      returnMenuButtons.forEach(button => {
        button.addEventListener('click', handleReturnClick);
      });
    };

    const handleReturnClick = () => {
      document.querySelector('.header__home-link').classList.remove('hidden');
      document.querySelector('.header__return').classList.remove('header__return--active');
      document.querySelector('.menu-item__link--search').classList.remove('hidden');

      const activeChildren = document.querySelectorAll('.menu-item__children--active');
      activeChildren.forEach(nav => {
        nav.classList.remove('menu-item__children--active');
        nav.classList.add('menu-item__children--inactive');

      });
    };

    window.addEventListener('resize', handleResize);
    handleResize();
  };

  mobileSubMenu();

  const toggleMobileNav = () => {
    const mobileHamburger = document.querySelector('.header__mobile-hamburger');
    const mobileHamburgerClose = document.querySelector('.header__mobile-hamburger--close');

    mobileHamburger.addEventListener('click', () => {
      document.querySelector('.header__return').classList.toggle('hidden');
      document.querySelector('.header__mobile-hamburger--open').classList.toggle('hidden');
      document.querySelector('.header__mobile-hamburger--close').classList.toggle('hidden');

      if (document.querySelector('.menu-item__children--active')) {
        document.querySelector('.submenu__return').click();
      }

      const mobileNav = document.querySelector('.header__navigation-mobile');
      const body = document.querySelector('body');
      const header = document.querySelector('header');

      if (mobileNav.classList.contains('header__navigation-mobile--active')) {
        mobileNav.classList.remove('header__navigation-mobile--active');
        mobileNav.classList.add('header__navigation-mobile--inactive');
        body.classList.remove('overflow-hidden');
        header.style.height = "unset";
        header.classList.remove('overflow-hidden');
      } else {
        mobileNav.classList.remove('header__navigation-mobile--inactive');
        mobileNav.classList.add('header__navigation-mobile--active');
        body.classList.add('overflow-hidden');
        header.style.height = "100vh";
        header.classList.add('overflow-hidden');
      }
    });
  };

  toggleMobileNav();


  const toggleSearch = () => {
    const searchIcon = document.querySelectorAll('.menu-item__link--search-icon');

    searchIcon.forEach(icon => {
      icon.addEventListener('click', () => {
        const parent = icon.parentElement;

        if (parent.querySelector('.search__form')) {
          parent.querySelector('.search__form-container').classList.toggle('hidden');
          parent.querySelectorAll('.search__close').forEach(close => {
            close.addEventListener('click', () => {
              parent.querySelector('.search__form-container').classList.add('hidden');
            });
          }
          );
        }

        const subMenus = document.querySelectorAll('.menu-item__children')

        subMenus.forEach(sub => {
          if(!sub.classList.contains('hidden')) {
            sub.classList.add('hidden')
          }
        });
      });
    });
  };

  toggleSearch();
};

export default header;
