export default {
  data () {
    const validateAgreement = (rule, value, callback) => {
      if (!value) {
        callback(new Error(this.$t('form.rules.require')))
      } else {
        callback()
      }
    }

    const validatePhone = (rule, value, callback) => {
      const regex = /^\+?[1-9]\d{0,2}[\s.-]?\d{1,4}[\s.-]?\d{1,4}[\s.-]?\d{1,4}$/;
      if (!value.match(regex) && value.length > 0) {
        callback(new Error(this.$t('form.rules.phone')))
      } else {
        callback()
      }
    }

    const validatePhone2 = (rule, value, callback) => {
      const regex = /^\+?[1-9]\d{0,2}[\s.-]?\d{1,4}[\s.-]?\d{1,4}[\s.-]?\d{1,4}$/;

      if (value.length === 0) {
        callback(new Error(this.$t('form.rules.require')));
      } else if (!regex.test(value)) {
        callback(new Error(this.$t('form.rules.phone')));
      } else {
        callback();
      }
    };

    const validatePassword = (rule, value, callback) => {
      const regex = /^(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/
      if (!value.match(regex)) {
        callback(new Error('Hasło powinno co najmniej zawierać: 8 znaków, 1 dużą literę, 1 cyfrę.'))
      } else {
        callback()
      }
    }

    const validateRePassword = (rule, value, callback) => {
      if (value !== this.form.password) {
        callback(new Error('Podane hasła nie są takie same.'))
      } else {
        callback()
      }
    }

    return {
      rules: {
        name: [
          { required: true, message: this.$t('form.rules.require'), trigger: 'blur' }
        ],
        lastName: [
          { required: true, message: this.$t('form.rules.require'), trigger: 'blur' }
        ],
        email: [
          { required: true, message: this.$t('form.rules.require'), trigger: 'blur' },
          { type: 'email', message: this.$t('form.rules.email'), trigger: 'blur' }
        ],
        phone: [
          { validator: validatePhone, trigger: 'change' }
        ],
        phone2: [
          { validator: validatePhone2, trigger: 'change' }
        ],
        department: [
          { required: true, message: this.$t('form.rules.require'), trigger: 'blur' }
        ],
        company: [
          { required: true, message: this.$t('form.rules.require'), trigger: 'blur' }
        ],
        subject: [
          { required: true, message: this.$t('form.rules.require'), trigger: 'blur' }
        ],
        message: [
          { required: true, message: this.$t('form.rules.require'), trigger: 'blur' }
        ],
        attachment: [
          { required: true, message: this.$t('form.rules.require'), trigger: 'blur' }
        ],
        agreement: [
          { required: true, message: this.$t('form.rules.require'), trigger: 'blur' },
          { validator: validateAgreement, trigger: 'change' }
        ],
        agreement2: [
          { required: true, message: this.$t('form.rules.require'), trigger: 'blur' },
          { validator: validateAgreement, trigger: 'change' }
        ],
        agreement3: [
          { required: true, message: this.$t('form.rules.require'), trigger: 'blur' },
          { validator: validateAgreement, trigger: 'change' }
        ],
        password: [
          { required: true, message: this.$t('form.rules.require'), trigger: 'blur' },
          { validator: validatePassword, trigger: 'blur' }
        ],
        repassword: [
          { required: true, message: this.$t('form.rules.require'), trigger: 'blur' },
          { validator: validatePassword, trigger: 'blur' },
          { validator: validateRePassword, trigger: 'blur' }
        ],
        fileList: [
          { required: true, message: this.$t('form.rules.attachments'), trigger: 'blur' }
        ]
      }
    }
  }
}
