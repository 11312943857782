import { createStore } from 'vuex';

import settings from "../settings";

// modules
//import module from './modules/module';

// plugin
// import plugin from './plugins/';

const state = {
  home: settings.home,
  context: settings.context,
  form_settings: settings.form_settings,
  thank_you_page: settings.thank_you_page,
  branches_location: settings.branches_location,
};

const getters = {};

const mutations = {};

const actions = {};

const store = createStore({
  state,
  getters,
  mutations,
  actions,
  modules: {
    //module
  },
  plugins: [
    //plugin
  ]
});

export default store
