export const Emit = {
  beforeMount(el, binding) {
    if (!binding.value || !binding.value.emit) {
      console.warn('Emit event or data is not defined in binding value');
      return;
    }

    el.__emitHandler__ = () => {
      binding.instance.$emitter.emit(binding.value.emit, binding.value.data);
    };
    el.addEventListener('click', el.__emitHandler__);
  },
  beforeUnmount(el) {
    if (el.__emitHandler__) {
      el.removeEventListener('click', el.__emitHandler__);
      delete el.__emitHandler__;
    }
  }
};
