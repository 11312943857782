// deep copy window object (break references)

const settings = (function () {
  try {
    return JSON.parse(
      JSON.stringify(window.theme)
    )
  } catch (e) {
    console.error(e);
    return {}
  }
})()

export default settings
