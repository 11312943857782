export const ScrollTo = {
  beforeMount(el, binding) {
    if (!binding.value || !binding.value.element) {
      console.warn('ScrollTo element is not defined in binding value');
      return;
    }

    el.__scrollToHandler__ = (e) => {
      binding.instance?.ScrollTo(binding.value.element, e);
    };

    el.addEventListener('click', el.__scrollToHandler__);
  },
  beforeUnmount(el) {
    if (el.__scrollToHandler__) {
      el.removeEventListener('click', el.__scrollToHandler__);
      delete el.__scrollToHandler__;
    }
  }
};
