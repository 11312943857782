import { computed } from 'vue';
export default {
  name: 'Mosaic',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    textRead: {
      type: String,
      default: 'Read more'
    }
  },
  setup(props) {
    const width = window.innerWidth;

    // Define the number of items per row
    const rows = computed(() => {
      const result = [];
      let i = 0;
      while (i < props.items.length) {
        const single = props.items[i]; // Item for the single column
        const multiple = props.items.slice(i + 1, i + 3); // Next two items for the multiple column

        result.push({
          single,
          multiple
        });
        i += 3; // Move to the next set of items
      }
      return result;
    });
    return {
      rows,
      width
    };
  }
};