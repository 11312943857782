import { ref, reactive, watch, onMounted, onBeforeUnmount } from 'vue';
export default {
  name: 'Filters',
  props: {
    filters: {
      type: Array,
      required: true
    },
    slug: {
      type: String,
      default: ''
    },
    remove: {
      type: String,
      default: ''
    },
    subbmit: {
      type: String,
      default: ''
    },
    tabsIs: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const width = ref(window.innerWidth);
    const selectedFilters = reactive({});
    const selectAll = reactive({});
    const activeTab = ref('');
    const updateWidth = () => {
      width.value = window.innerWidth;
    };
    const toggleSelectAll = filter => {
      if (selectAll[filter.name]) {
        selectedFilters[filter.name] = filter.list.map(cat => String(cat.term_id));
      } else {
        selectedFilters[filter.name] = [];
      }
    };
    const confirmSelection = () => {
      const url = new URL(window.location.href);
      const allSelectedFilters = Object.values(selectedFilters).flat();
      if (allSelectedFilters.length > 0) {
        url.searchParams.set(props.slug, allSelectedFilters.join(','));
      } else {
        url.searchParams.delete(props.slug);
      }
      window.location.href = url.href;
    };
    const removeAll = () => {
      props.filters.forEach(filter => {
        selectedFilters[filter.name] = [];
        selectAll[filter.name] = false;
      });
      const url = new URL(window.location.href);
      url.searchParams.delete(props.slug);
      window.location.href = url.href;
    };
    const initSelectedFiltersFromURL = () => {
      const url = new URL(window.location.href);
      const existingValues = url.searchParams.get(props.slug);
      if (existingValues) {
        const selectedValues = decodeURIComponent(existingValues).split(',');
        props.filters.forEach(filter => {
          selectedFilters[filter.name] = selectedValues.filter(id => filter.list.some(cat => String(cat.term_id) === id));
          selectAll[filter.name] = selectedFilters[filter.name].length === filter.list.length;
        });
      } else {
        props.filters.forEach(filter => {
          selectedFilters[filter.name] = [];
          selectAll[filter.name] = false;
        });
      }
    };
    onMounted(() => {
      window.addEventListener('resize', updateWidth);
      initSelectedFiltersFromURL();
    });
    onBeforeUnmount(() => {
      window.removeEventListener('resize', updateWidth);
    });
    watch(selectedFilters, newVal => {
      props.filters.forEach(filter => {
        selectAll[filter.name] = newVal[filter.name].length === filter.list.length;
      });
    }, {
      deep: true
    });
    return {
      width,
      selectedFilters,
      selectAll,
      activeTab,
      toggleSelectAll,
      confirmSelection,
      removeAll
    };
  }
};