const hero = () => {
  const videoElement = document.querySelector('.hero #hero-video')

  if (videoElement) {
    const videoSection = document.querySelector('.hero__background')
    let isVideoLoaded = false;

    // Funkcja do ustawiania odpowiedniego źródła wideo
    function setVideoSource() {
      const windowWidth = window.innerWidth;

      // Pobierz dane z atrybutów data-*
      const videoUrl = windowWidth < 768
        ? videoElement.getAttribute("data-video-mobile")
        : videoElement.getAttribute("data-video");
      const videoType = windowWidth < 768
        ? videoElement.getAttribute("data-video-mobile-type")
        : videoElement.getAttribute("data-video-type");

      // Tworzenie elementu <source>
      const videoSource = document.createElement("source");
      videoSource.src = videoUrl;
      videoSource.type = videoType;

      // Dodaj źródło do <video>
      videoElement.appendChild(videoSource);
    }

    // Funkcja ładowania wideo (lazy loading)
    function loadVideo() {
      if (!isVideoLoaded) {
        setVideoSource();
        videoElement.classList.remove("hidden"); // Wyświetl wideo
        isVideoLoaded = true;
      }
    }

    // Intersection Observer do lazy loading
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            loadVideo();
            observer.disconnect(); // Odłącz obserwatora po załadowaniu wideo
          }
        });
      },
      { threshold: 0.5 } // 50% widoczności sekcji
    );

    observer.observe(videoSection);

    // Aktualizowanie źródła przy zmianie rozmiaru okna (opcjonalnie)
    window.addEventListener("resize", () => {
      if (isVideoLoaded) {
        videoElement.innerHTML = ""; // Usuń istniejące źródła
        setVideoSource();
      }
    });
  }
}

export default hero;
