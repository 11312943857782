import { Swiper, SwiperSlide } from 'swiper/vue';
import { FreeMode } from 'swiper/modules';
import 'swiper/css';
export default {
  name: 'CategorySwiper',
  props: {
    items: {
      type: Array,
      required: true
    },
    projects: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  setup() {
    const onSwiper = swiper => {};
    const onSlideChange = () => {};
    return {
      onSwiper,
      onSlideChange,
      modules: [FreeMode]
    };
  }
};