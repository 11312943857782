const certification = () => {
  /* if (document.body.classList.contains('page-template-template-certification')) {
    const certificates = document.querySelectorAll('.certification__item');
    const dialog = document.querySelector('.certification__popup');

    certificates.forEach(e => {
      e.addEventListener('click', () => {
        const srcImage = e.getAttribute('data-image-src')
        dialog.setAttribute('data-image-src',srcImage)
      });
    });
  } */
};

export default certification;
