export default {
  methods: {
    Recaptcha() {
      return new Promise((resolve, reject) => {
        grecaptcha.ready(() => {
          grecaptcha
            .execute(process.env.RECAPTCHA_SITE_KEY, {action: 'submit'})
            .then((token) => {
              resolve(token)
            })
            .catch((error) => {
              this.MessageAlert({type: 'error', message: 'Napotkaliśmy problem z weryfikacją Recaptcha.', duration: 0, showClose: true})
              reject(error)
            })
        })
      })
    }
  }
}
