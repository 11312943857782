import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "px-[1.2rem] py-[.4rem]" }
const _hoisted_2 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_swiper_slide = _resolveComponent("swiper-slide")
  const _component_swiper = _resolveComponent("swiper")

  return ($props.projects)
    ? (_openBlock(), _createBlock(_component_swiper, {
        key: 0,
        onSwiper: $setup.onSwiper,
        onSlideChange: $setup.onSlideChange,
        slidesPerView: "auto",
        "space-between": 15,
        class: "py-[.8rem]",
        modules: $setup.modules,
        freeMode: true
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, (item, index) => {
            return (_openBlock(), _createBlock(_component_swiper_slide, {
              key: index,
              class: ""
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, _toDisplayString(item.name), 1)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["onSwiper", "onSlideChange", "modules"]))
    : (_openBlock(), _createBlock(_component_swiper, {
        key: 1,
        onSwiper: $setup.onSwiper,
        onSlideChange: $setup.onSlideChange,
        slidesPerView: "auto",
        "space-between": 20,
        class: "py-[.8rem] normal",
        modules: $setup.modules,
        freeMode: true
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, (item, index) => {
            return (_openBlock(), _createBlock(_component_swiper_slide, {
              key: index,
              class: ""
            }, {
              default: _withCtx(() => [
                _createElementVNode("a", {
                  href: item.url,
                  class: "px-[.8rem] py-[.4rem]"
                }, _toDisplayString(item.name), 9, _hoisted_2)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["onSwiper", "onSlideChange", "modules"]))
}