const products_category = () => {
  const btn = document.querySelector('.products-category__menu--filter')

  if (btn){
    const el = document.querySelector('.products-category__menu--mobile')
    const close = document.querySelector('.products-category__menu--close')

    btn.addEventListener('click', () => {
      btn.classList.add('hidden')
      el.classList.add('only-mobile:max-h-[100rem]')
      el.classList.remove('only-mobile:max-h-[0rem]')
    })

    close.addEventListener('click', () => {
      btn.classList.remove('hidden')
      el.classList.add('only-mobile:max-h-[0rem]')
      el.classList.remove('only-mobile:max-h-[100rem]')
    })
  }
};

export default products_category;
