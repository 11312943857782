import newsletter from "@scripts/components/newsletter.js";

const video = () => {
  const videoSection2 = document.querySelector(".video-block");
  if (videoSection2) {
    const videoElements = videoSection2.querySelectorAll("video.video__content__video-player");

// Funkcja do załadowania wideo
    function loadVideo() {
      videoElements.forEach((videoElement) => {
        const src = videoElement.getAttribute("data-src");
        const type = videoElement.getAttribute("data-type");

        if (src && type) {
          const source = document.createElement("source");
          source.src = src;
          source.type = type;

          videoElement.appendChild(source);
          videoElement.classList.remove("hidden"); // Pokaż wideo
        }
      })
    }

    // Intersection Observer
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            loadVideo();
            observer.disconnect(); // Przestań obserwować
          }
        });
      },
      {threshold: 0.5} // 50% widoczności sekcji
    );

    observer.observe(videoSection2);
  }
}

export default video;
