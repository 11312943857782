import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Autoplay, Thumbs, FreeMode } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import { ref, nextTick } from 'vue';
import { ElDialog } from 'element-plus';
export default {
  name: 'ImageCarousel',
  components: {
    Swiper,
    SwiperSlide,
    ElDialog
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    slidertype: {
      type: String,
      required: true,
      default: 'logos'
    }
  },
  setup(props) {
    const thumbsSwiper = ref(null);
    const lightboxSwiper = ref(null);
    const activeIndex = ref(0);
    const setThumbsSwiper = swiper => {
      thumbsSwiper.value = swiper;
    };
    const lightboxVisible = ref(false);
    const openLightbox = index => {
      const dialogElements = document.querySelectorAll('.image-carousel');
      activeIndex.value = index;
      lightboxVisible.value = true;
      dialogElements.forEach(e => {
        e.style.zIndex = '1000000000';
      });
    };
    const onLightboxClose = index => {
      const dialogElements = document.querySelectorAll('.image-carousel');
      dialogElements.forEach(e => {
        e.style.zIndex = 'unset';
      });
    };
    const onLightboxOpen = () => {
      lightboxSwiper.value.slideTo(activeIndex.value);
    };
    const onSwiper = swiper => {
      lightboxSwiper.value = swiper;
    };
    const onSlideChange = () => {};
    let breakpoints;
    if (props.slidertype === 'logos') {
      breakpoints = {
        320: {
          slidesPerView: 2,
          spaceBetween: 20
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 30
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 40
        },
        1200: {
          slidesPerView: 5
        }
      };
    } else {
      breakpoints = {
        320: {
          slidesPerView: 2.2,
          centeredSlides: false,
          spaceBetween: 24
        },
        768: {
          slidesPerView: 3.2,
          spaceBetween: 32
        },
        1200: {
          slidesPerView: 4.2
        }
      };
    }
    const altText = 'Carousel Image';
    return {
      onSwiper,
      onSlideChange,
      breakpoints,
      modules: [Navigation, Autoplay, Thumbs],
      lightboxVisible,
      activeIndex,
      openLightbox,
      onLightboxOpen,
      thumbsSwiper,
      setThumbsSwiper,
      lightboxSwiper,
      altText,
      onLightboxClose
    };
  }
};