import domReady from '@roots/sage/client/dom-ready';

import {createApp} from 'vue';
import App from './vue/App.vue';

import axios from 'axios'
import mitt from 'mitt'

import {Emit} from './vue/directives/emit.js';
import {Intersect} from './vue/directives/intersect.js';
import {ScrollTo} from './vue/directives/scrollto.js';

import {example} from './vue/filters/example.js';

import {scrollTo} from './vue/mixins/ScrollTo.js';

import {i18n} from './vue/i18n'

import store from './vue/store';

import header from './modules/header';
import newsletter from './components/newsletter'
import video from './components/video'
import hero from './components/hero'
import faq from './page/faq'
import documentation from './page/documentation'
import certification from './page/certification'
import products_category from './page/products-category.js'

import 'swiper/css';

domReady(async () => {
  const app = createApp(App);

  app.config.globalProperties.$axios = axios

  app.config.globalProperties.$axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

  let token = document.head.querySelector('meta[name="csrf-token"]');

  if (token) { // X-CSRF-TOKEN
    app.config.globalProperties.$axios.defaults.headers.common['X-WP-Nonce'] = token.content;
  } else {
    console.error('CSRF token not found');
  }

  app.config.globalProperties.$emitter = mitt()

  // Apply global mixin and directives
  app.mixin(scrollTo);

  app.directive('emit', Emit);
  app.directive('intersect', Intersect);
  app.directive('scrollto', ScrollTo);

  // Add global filters
  app.config.globalProperties.$filters = {
    example
  };

  // Use i18n and store
  app.use(i18n);
  app.use(store);

  // Mount application
  app.mount('#app');

  header();
  newsletter();
  video();
  hero();
  faq();
  documentation();
  certification();
  products_category();

  setTimeout(() => {
    const script = document.createElement('script');
    script.src = "https://www.google.com/recaptcha/api.js?render="+process.env.RECAPTCHA_SITE_KEY;
    script.async = true;
    document.head.appendChild(script);
  }, 2000); // Opóźnienie w milisekundach
});

import.meta.webpackHot?.accept(console.error);
