const newsletter = () => {
  const form = document.querySelector('.newsletter__form');
  const newsletter_container = document.querySelector('.newsletter__form-container');
  const newsletter_text = document.querySelector('.newsletter__text');
  const newsletter_success = document.querySelector('.newsletter__response-success');
  const newsletter_response = document.querySelector('.newsletter__response')

  if (form) {
    form.addEventListener('submit', async (event) => {
      event.preventDefault();

      const email = document.getElementById('email').value;
      const acceptance1 = document.getElementById('acceptance1');
      const acceptance2 = document.getElementById('acceptance2');

      document.querySelector('.newsletter__response').innerHTML = ''

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (email === '' ) {
        document.querySelector('.newsletter__response').style.color="red"
        document.querySelector('.newsletter__response').innerHTML = 'You must enter your email address.';
        return;
      } else if (!emailRegex.test(email)) {
        document.querySelector('.newsletter__response').style.color="red"
        document.querySelector('.newsletter__response').innerHTML = 'You must provide a valid email address.';
        return;
      }

      if ((acceptance1 && !acceptance1.checked) || (acceptance2 && !acceptance2.checked)) {
        document.querySelector('.newsletter__response').style.color="red"
        document.querySelector('.newsletter__response').innerHTML = 'You must accept the required consents.';
        return;
      }
      document.querySelector('.newsletter__response').style.color="red"

      const formData = new FormData();
      formData.append('action', 'my_newsletter_subscribe');
      formData.append('email', email);
      if (acceptance1) {
        formData.append('acceptance1', acceptance1.checked ? '1' : '0');
      }
      if (acceptance2) {
        formData.append('acceptance2', acceptance2.checked ? '1' : '0');
      }

      try {
        const response = await fetch(myAjax.ajaxurl, {
          method: 'POST',
          body: formData
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        let responseData = await response.text();
        responseData = JSON.parse(responseData)
        if (responseData.response_code === 200 || responseData.response_code === 202) {
          //document.querySelector('.newsletter__response').style.color="green";
          form.classList.add('hidden');
          newsletter_text.classList.add('hidden');
          newsletter_response.classList.add('hidden')
          newsletter_success.classList.remove('hidden');
          newsletter_success.classList.add('flex');
          newsletter_container.classList.remove('basis-4/12')
        } else {
          document.querySelector('.newsletter__response').style.color="red"
        }
        document.querySelector('.newsletter__response').innerHTML = responseData.response_message;
      } catch (error) {
        console.error("Error while creating the application.", error);
      }
    });
  }
}

export default newsletter;
