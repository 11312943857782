const faq = () => {
  if (document.body.classList.contains('page-template-template-faq')) {
    const main = document.querySelector('.faq__tabs');
    const faq = main.querySelectorAll('.el-tab-pane');

    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        const idCategory = entry.target.getAttribute('id');
        const idMenu = `${idCategory}-menu`;
        const menuElement = document.getElementById(idMenu);

        if (entry.isIntersecting) {
          document.querySelectorAll('.faq__single').forEach(el => el.style.opacity = 0.7);
          menuElement.style.opacity = 1;
        }
      });
    }, observerOptions);

    faq.forEach(e => {
      const faq_menu = e.querySelectorAll('.faq__single');

      if (window.innerWidth < 768) {
        e.addEventListener('click', function () {
          faq_menu.forEach(s => {
            s.classList.toggle('hidden')
          })
        })
      }

      faq_menu.forEach(s => {
        const idMenu = s.getAttribute('id');
        const idCategory = idMenu.replace('-menu', '');
        const elementToScroll = document.getElementById(idCategory);

        observer.observe(elementToScroll);

        s.addEventListener('click', () => {
          window.scrollTo({top: elementToScroll.offsetTop + main.offsetTop - 100, behavior: 'smooth'});
        });
      });
    });
  }
};

export default faq;
