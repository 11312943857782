import MessageAlert from '../../../mixins/MessageAlert';
import Recaptcha from '../../../mixins/Recaptcha/Recaptcha.js';
import Loading from '../../../mixins/Loading';
import FormRules from '../../../mixins/Forms/FormRules';

import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/message/style/css';
export default {
  name: 'JobOfferForm',
  props: {
    id: {
      type: Number,
      default: 0
    },
    nonce: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    }
  },
  mixins: [Recaptcha, MessageAlert, Loading, FormRules],
  data: () => {
    return {
      form: {
        name: '',
        lastName: '',
        email: '',
        phone2: '',
        department: '',
        agreement: false,
        agreement2: false,
        fileList: []
      },
      send: false
    };
  },
  computed: {
    agreements() {
      return this.$store.state.form_settings.agreements_job_offer;
    },
    departments() {
      return this.$store.state.form_settings.departments;
    },
    thankYouPage() {
      return this.$store.state.thank_you_page?.job_offer;
    }
  },
  methods: {
    beforeUpload(file) {
      if (file.type !== 'image/jpeg' && file.type !== 'image/jpg' && file.type !== 'image/png' && file.type !== 'application/pdf' && file.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' && file.type !== 'application/msword') {
        ElMessage.error('Avatar picture must be JPG format!');
        return false;
      }
      return true;
    },
    submitForm(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          this.Loading();
          await this.Recaptcha().then(async token => {
            const agree = [{
              text: this.agreements.agreement_1.replace(/(<([^>]+)>)/ig, ''),
              accepted: this.form.agreement
            }];
            if (this.agreements.agreement_2) {
              agree.push({
                text: this.agreements.agreement_2.replace(/(<([^>]+)>)/ig, ''),
                accepted: this.form.agreement
              });
            }
            const formData = new FormData();
            this.form.fileList.forEach(file => {
              formData.append('files[]', file.raw); // 'files[]' to klucz używany na backendzie
            });
            formData.append('id', this.id);
            formData.append('title', this.type === 'general' ? this.form.department : this.title);
            formData.append('name', this.form.name);
            formData.append('last_name', this.form.lastName);
            formData.append('email', this.form.email);
            formData.append('phone', this.form.phone2);
            formData.append('agreements', JSON.stringify(agree));
            await this.$axios.post('/wp-json/api/form/job-offer', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
                Token: token
              }
            }).then(async response => {
              let name = this.type === 'general' ? this.form.department : this.title;
              name = name.toLowerCase().replace(/ /g, "-");
              window.location.href = this.thankYouPage + '?offer=' + name;
              this.send = true;
            }).catch(e => {
              this.MessageAlert({
                type: 'error',
                message: this.$t('form.notices.send_error'),
                duration: 0,
                showClose: true
              });
            });
          });
          this.Loading();
        } else {
          return false;
        }
      });
    }
  }
};