import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "contact-form w-full" }
const _hoisted_2 = { class: "custom-form__row w-full flex only-mobile:flex-col items-center gap-[2.4rem]" }
const _hoisted_3 = { class: "custom-form__row w-full flex only-mobile:flex-col items-center gap-[2.4rem]" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_checkbox = _resolveComponent("el-checkbox")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_form = _resolveComponent("el-form")
  const _directive_loading = _resolveDirective("loading")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_form, {
      model: _ctx.form,
      rules: _ctx.rules,
      ref: "contactForm",
      class: _normalizeClass(["contact-form__form custom-form", _ctx.$attrs.class])
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_form_item, {
            label: _ctx.$t('form.label.name'),
            prop: "name"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.form.name,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.form.name) = $event)),
                placeholder: _ctx.$t('form.placeholder.name')
              }, null, 8, ["modelValue", "placeholder"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_form_item, {
            label: _ctx.$t('form.label.company'),
            prop: "company"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.form.company,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.form.company) = $event)),
                placeholder: _ctx.$t('form.placeholder.company')
              }, null, 8, ["modelValue", "placeholder"])
            ]),
            _: 1
          }, 8, ["label"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_el_form_item, {
            label: _ctx.$t('form.label.email'),
            prop: "email"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.form.email,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.form.email) = $event)),
                placeholder: _ctx.$t('form.placeholder.email')
              }, null, 8, ["modelValue", "placeholder"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_form_item, {
            label: _ctx.$t('form.label.phone'),
            prop: "phone"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.form.phone,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.form.phone) = $event)),
                placeholder: _ctx.$t('form.placeholder.phone')
              }, null, 8, ["modelValue", "placeholder"])
            ]),
            _: 1
          }, 8, ["label"])
        ]),
        _createVNode(_component_el_form_item, {
          label: _ctx.$t('form.label.message'),
          prop: "message"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              type: "textarea",
              modelValue: _ctx.form.message,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.form.message) = $event)),
              rows: 5,
              placeholder: _ctx.$t('form.placeholder.message')
            }, null, 8, ["modelValue", "placeholder"])
          ]),
          _: 1
        }, 8, ["label"]),
        ($options.agreements.agreement_1)
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 0,
              prop: $options.agreements.requier_agreement_1 ? 'agreement' : '',
              class: "form-small-mg"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_checkbox, {
                  modelValue: _ctx.form.agreement,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_ctx.form.agreement) = $event))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      innerHTML: $options.agreements.agreement_1,
                      class: "flex"
                    }, null, 8, _hoisted_4)
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["prop"]))
          : _createCommentVNode("", true),
        ($options.agreements.agreement_2)
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 1,
              prop: $options.agreements.requier_agreement_2 ? 'agreement2' : ''
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_checkbox, {
                  modelValue: _ctx.form.agreement2,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => ((_ctx.form.agreement2) = $event))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      innerHTML: $options.agreements.agreement_2,
                      class: "flex"
                    }, null, 8, _hoisted_5)
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["prop"]))
          : _createCommentVNode("", true),
        ($options.agreements.agreement_3)
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 2,
              prop: $options.agreements.requier_agreement_3 ? 'agreement3' : ''
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_checkbox, {
                  modelValue: _ctx.form.agreement3,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => ((_ctx.form.agreement3) = $event))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      innerHTML: $options.agreements.agreement_3,
                      class: "flex"
                    }, null, 8, _hoisted_6)
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["prop"]))
          : _createCommentVNode("", true),
        _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [
            _withDirectives((_openBlock(), _createBlock(_component_el_button, {
              class: _normalizeClass(["btn", {'btn--secondary': _ctx.$attrs.class === 'white', 'btn--tertiary': _ctx.$attrs.class === 'dark',}]),
              onClick: _cache[8] || (_cache[8] = $event => ($options.submitForm('contactForm')))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('form.send_message')), 1)
              ]),
              _: 1
            }, 8, ["class"])), [
              [_directive_loading, _ctx.loading]
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model", "rules", "class"])
  ]))
}