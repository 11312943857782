import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "job-offer-form w-full" }
const _hoisted_2 = { class: "custom-form__row w-full flex only-mobile:flex-col items-center gap-[2.4rem]" }
const _hoisted_3 = { class: "custom-form__row w-full flex only-mobile:flex-col items-center gap-[2.4rem]" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")
  const _component_el_upload = _resolveComponent("el-upload")
  const _component_el_checkbox = _resolveComponent("el-checkbox")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_form = _resolveComponent("el-form")
  const _directive_loading = _resolveDirective("loading")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_form, {
      model: _ctx.form,
      rules: _ctx.rules,
      ref: "jobOfferForm",
      class: _normalizeClass(["job-offer-form__form custom-form", _ctx.$attrs.class])
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_form_item, {
            label: _ctx.$t('form.label.name'),
            prop: "name"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.form.name,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.form.name) = $event)),
                placeholder: _ctx.$t('form.placeholder.name')
              }, null, 8, ["modelValue", "placeholder"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_form_item, {
            label: _ctx.$t('form.label.lastName'),
            prop: "lastName"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.form.lastName,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.form.lastName) = $event)),
                placeholder: _ctx.$t('form.placeholder.lastName')
              }, null, 8, ["modelValue", "placeholder"])
            ]),
            _: 1
          }, 8, ["label"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_el_form_item, {
            label: _ctx.$t('form.label.email'),
            prop: "email"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.form.email,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.form.email) = $event)),
                placeholder: _ctx.$t('form.placeholder.email')
              }, null, 8, ["modelValue", "placeholder"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_form_item, {
            label: _ctx.$t('form.label.phone2'),
            prop: "phone2"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.form.phone2,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.form.phone2) = $event)),
                placeholder: _ctx.$t('form.placeholder.phone')
              }, null, 8, ["modelValue", "placeholder"])
            ]),
            _: 1
          }, 8, ["label"])
        ]),
        ($props.type === 'general')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 0,
              label: _ctx.$t('form.label.department'),
              prop: "department"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: _ctx.form.department,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.form.department) = $event)),
                  placeholder: _ctx.$t('form.placeholder.department'),
                  teleported: false
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.departments, (item, index) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: index,
                        label: item.department,
                        value: item.department
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["label"]))
          : _createCommentVNode("", true),
        _createVNode(_component_el_form_item, { prop: "fileList" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_upload, {
              "file-list": _ctx.form.fileList,
              "onUpdate:fileList": _cache[5] || (_cache[5] = $event => ((_ctx.form.fileList) = $event)),
              class: "upload-demo",
              limit: 2,
              accept: ".pdf, .doc, .docx, .jpg, .png",
              "before-upload": $options.beforeUpload
            }, {
              default: _withCtx(() => _cache[9] || (_cache[9] = [
                _createElementVNode("div", { class: "flex items-center text-white copy2" }, [
                  _createElementVNode("i", { class: "icon flex tlc-icon-attachment text-gray mr-[0.4rem]" }),
                  _createTextVNode(" Attach CV")
                ], -1)
              ])),
              _: 1
            }, 8, ["file-list", "before-upload"])
          ]),
          _: 1
        }),
        ($options.agreements.agreement_1)
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 1,
              prop: $options.agreements.requier_agreement_1 ? 'agreement' : '',
              class: "form-small-mg"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_checkbox, {
                  modelValue: _ctx.form.agreement,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => ((_ctx.form.agreement) = $event))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      innerHTML: $options.agreements.agreement_1,
                      class: "flex"
                    }, null, 8, _hoisted_4)
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["prop"]))
          : _createCommentVNode("", true),
        ($options.agreements.agreement_2)
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 2,
              prop: $options.agreements.requier_agreement_2 ? 'agreement2' : ''
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_checkbox, {
                  modelValue: _ctx.form.agreement2,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => ((_ctx.form.agreement2) = $event))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      innerHTML: $options.agreements.agreement_2,
                      class: "flex"
                    }, null, 8, _hoisted_5)
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["prop"]))
          : _createCommentVNode("", true),
        _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [
            _withDirectives((_openBlock(), _createBlock(_component_el_button, {
              class: _normalizeClass(["btn only-mobile:mx-auto", {'btn--primary': _ctx.$attrs.class === 'white', 'btn--tertiary': _ctx.$attrs.class === 'dark',}]),
              onClick: _cache[8] || (_cache[8] = $event => ($options.submitForm('jobOfferForm')))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('form.send_message')), 1)
              ]),
              _: 1
            }, 8, ["class"])), [
              [_directive_loading, _ctx.loading]
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model", "rules", "class"])
  ]))
}